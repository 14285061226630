import { Alert } from '@mui/material';
import { FC, useState, useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { FullscreenStatus } from '../../../ui';
import { PatientContainer } from '../../containers';
import { usePatient } from '../../hooks';
import { PatientScansScreen } from '../PatientScansScreen';
import { PatientScreen } from '../PatientScreen';
import { PatientFunctionalDynamicAssessmentScreen } from '../PatientFunctionalDynamicAssessmentScreen';
import { OuterTitleContainer, ButtonContainer } from './styles';
import { Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ExportPatientDataModal } from '../../components';
import { useExportPatientData } from '../../hooks';
import { enqueueSnackbar } from 'notistack';

export const PatientScreenRouter: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [data, loading, error, reload] = usePatient(id);
  const {
    isLoading: isLoadingExportPatientData,
    result: resultExportPatientData,
    exportData: requestExportPatientData,
  } = useExportPatientData();

  const [exportPatientDataModalOpen, setExportPatientDataModalOpen] =
    useState(false);

  useEffect(() => {
    if (resultExportPatientData) {
      setExportPatientDataModalOpen(false);
      enqueueSnackbar('Patient data exported successfully', {
        variant: 'success',
      });
    }
  }, [resultExportPatientData]);

  if (!data && loading) {
    return <FullscreenStatus loading />;
  }

  if (error || !data) {
    return <Alert color='error'>{error?.message || 'No data'}</Alert>;
  }

  const handleScansClick = (id: string | undefined) => {
    navigate(`/patients/${id}/scans`);
  };

  const handleExportPatientDataClick = () => {
    setExportPatientDataModalOpen(true);
  };

  return (
    <>
      <ExportPatientDataModal
        open={exportPatientDataModalOpen}
        onClose={() => setExportPatientDataModalOpen(false)}
        patientId={id}
        requestExportPatientData={requestExportPatientData}
        isLoadingExportPatientData={isLoadingExportPatientData}
      />
      <PatientContainer patient={data} reload={reload}>
        <DocumentTitle title={`${data.first_name} ${data.last_name}`} />
        <OuterTitleContainer>
          <Typography variant='h1'>
            {data.first_name} {data.last_name}
          </Typography>
          <ButtonContainer>
            <Button
              variant='containedLight'
              onClick={() => handleScansClick(id)}
            >
              Scans
            </Button>
            <Button variant='contained' onClick={handleExportPatientDataClick}>
              Export Patient Data
            </Button>
          </ButtonContainer>
        </OuterTitleContainer>
        <Routes>
          <Route index element={<PatientScreen />} />
          <Route path='scans' element={<PatientScansScreen />} />
          <Route
            path='functional-dynamic-assessment'
            element={<PatientFunctionalDynamicAssessmentScreen />}
          />
        </Routes>
      </PatientContainer>
    </>
  );
};
