import { format } from 'date-fns';
import { request, requestList } from '../../../network';
import { ListData, ListDataRequestParams } from '../../ui';
import { subThreeMonths } from '../hooks';
import { patientNamesService } from '../services';
import { config } from '../../../config';

export interface PatientModel {
  id: number;
  birthday?: string;
  first_name?: string;
  last_name?: string;
  next_scan_date?: string;
  age?: number | null; // Local field
  email_verified: boolean;
  // #TODO: describe model
}

const mapPatient = (data: PatientModel): PatientModel => {
  patientNamesService.addPatientName(
    data.id,
    `${data.first_name || ''} ${data.last_name || ''}`.trim()
  );

  return data;
};

export const getPatientList = async (
  data?: ListDataRequestParams
): Promise<ListData> => {
  const response = await requestList('/api/patients/', data);

  if (response.data) {
    response.data = response.data.map(mapPatient);
  }

  return response;
};

export const getPatient = async (
  id: number | string
): Promise<PatientModel> => {
  const response = await request({
    path: `/api/patients/${id}/`,
    method: 'GET',
    silent: true,
  });

  if (response.ok) {
    return mapPatient(response.data);
  }

  throw new Error(response.errorMessage);
};

export const updatePatient = async (
  data: PatientModel
): Promise<PatientModel> => {
  const response = await request({
    path: `/api/patients/${data.id}/`,
    method: 'PUT',
    body: data,
  });

  if (response.ok) {
    return mapPatient(response.data);
  }

  throw new Error(response.errorMessage);
};

export interface PatientCountResponse {
  total: number;
  new?: number;
}

export const getPatientCount = async (): Promise<PatientCountResponse> => {
  const response = await request({
    path: '/api/patients/',
    method: 'GET',
    silent: true,
  });

  if (response.ok) {
    return {
      total: response.data.count,
      new: 0,
    };
  }

  throw new Error(response.errorMessage);
};

export interface InvitePatientData {
  first_name: string;
  last_name: string;
  email: string;
  username?: string;
}

export const invitePatient = async (
  data: InvitePatientData
): Promise<PatientModel> => {
  const response = await request({
    path: '/api/patients/',
    method: 'POST',
    body: data,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export interface PatientChartData {
  data: PatientChartDataModel[];
}

export interface PatientChartDataModel {
  created_at: string;
  forward_bend: number | null;
  msl: number | null;
  plumb_shift: number | null;
  predicted_cobb: number | null;
  shoulder_asymmetry: number | null;
  trunk_height: number | null;
  trunk_shift: number | null;
  waist_asymmetry: number | null;
}

export const getPatientChart = async (
  patientId: number,
  startDate?: string,
  endDate?: string
): Promise<PatientChartData> => {
  const params: any = {
    page_size: '100',
    patient: patientId,
  };

  if (startDate) {
    params['after_date'] = startDate;
  } else {
    // default to 3 months
    params['after_date'] = format(
      subThreeMonths(new Date()),
      config.apiDateFormat
    );
  }

  if (endDate) {
    params['before_date'] = endDate;
  }

  const response = await request({
    path: '/api/graph/',
    method: 'GET',
    params,
  });

  if (response.ok) {
    return {
      data: response.data.results.filter((x: any) => x.cobb !== null),
    };
  }

  throw new Error(response.errorMessage);
};

export const exportPatientData = async (
  patientId: string | undefined,
  startDate: string,
  endDate: string
) => {
  const response = await request({
    path: `/api/patient_report/${patientId}/`,
    method: 'GET',
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const removePatientFromOrg = async (patientId: number) => {
  const response = await request({
    path: '/api/remove_patient_from_organization/',
    method: 'POST',
    body: {
      patient_id: patientId,
    },
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};
